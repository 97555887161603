<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
    scrollable
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        :fab="fab"
        :color="color || 'color3 color3Text--text'"
        :class="btnClass"
        :text="textBtn"
        :x-small="xSmall"
      >
        <v-icon :class="{'mr-3': !!text}" :small="xSmall">{{icon || 'fas fa-cloud-download-alt'}}</v-icon>
        {{text}}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Data Download</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color1Text" text icon @click.stop="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <data-exporter ref="data" :active="dialog"></data-exporter>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DataExporter from './DataExporter.vue'

export default {
  props: ['color', 'btnText', 'fab', 'icon', 'btnClass', 'textBtn', 'xSmall'],
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    text () {
      return this.btnText || this.fab ? '' : 'Download Teams'
    }
  },
  components: {
    'data-exporter': DataExporter
  }
}
</script>

<style>

</style>
