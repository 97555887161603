export default [
  'Men\'s Open',
  'Men\'s AAA',
  'Men\'s AA',
  'Men\'s A',
  'Men\'s BB',
  'Men\'s B',
  'Men\'s Collegiate 2\'s',
  'Men\'s Open Triples',
  'Men\'s AA Triples',
  'Men\'s A Triples',
  'Men\'s BB Triples',
  'Men\'s B Triples',
  'Men\'s 4\'s Open',
  'Men\'s 4\'s A',
  'Men\'s 4\'s B',
  'Men\'s Novice',
  'KOB Men\'s Open',
  'KOB Men\'s AAA',
  'KOB Men\'s AA',
  'KOB Men\'s A',
  'KOB Men\'s B',
  'KOB Men\'s (Mixed)',
  'Men\'s Blind Draw Open',
  'Men\'s Blind Draw AA',
  'Men\'s Blind Draw A',
  'Men\'s Blind Draw B',
  'Men\'s Open Early',
  'Men\'s Open Late',
  'Men\'s AA Early',
  'Men\'s AA Late',
  'Men\'s A Early',
  'Men\'s A Late',
  'Men\'s B Early',
  'Men\'s B Late',
  'Men\'s Quads AA',
  'Men\'s Quads A',
  'Men\'s Quads BB',
  'Men\'s Quads B',
  'Men\'s Masters 50+',
  'Men\'s Masters 45+',
  'Men\'s Masters 40+',
  'Men\'s Dino 80',
  'Men\'s 4\'s Novice',
  'Men\'s BBB',
  'Men\'s Masters 35+',
  'Men\'s Masters 45+',
  'Men\'s Masters 55+',
  'Men\'s 2\'s Non Ranked',
  'Men\'s Pickup',
  'Women\'s',
  'Women\'s Open',
  'Women\'s AAA',
  'Women\'s AA',
  'Women\'s A',
  'Women\'s BB',
  'Women\'s B',
  'Women\'s Collegiate 2\'s',
  'Women\'s Open Triples',
  'Women\'s AA Triples',
  'Women\'s A Triples',
  'Women\'s BB Triples',
  'Women\'s B Triples',
  'Women\'s 4\'s Open',
  'Women\'s 4\'s A',
  'Women\'s 4\'s B',
  'Women\'s Novice',
  'KOB Women\'s Open',
  'KOB Women\'s AAA',
  'KOB Women\'s AA',
  'KOB Women\'s A',
  'KOB Women\'s B',
  'KOB Women\'s (Mixed)',
  'Women\'s Blind Draw Open',
  'Women\'s Blind Draw AA',
  'Women\'s Blind Draw A',
  'Women\'s Blind Draw B',
  'Women\'s Open Early',
  'Women\'s Open Late',
  'Women\'s AA Early',
  'Women\'s AA Late',
  'Women\'s A Early',
  'Women\'s A Late',
  'Women\'s B Early',
  'Women\'s B Late',
  'Women\'s Dino 80',
  'Women\'s Masters 50+',
  'Women\'s Masters 45+',
  'Women\'s Masters 40+',
  'Women\'s 4\'s Novice',
  'Women\'s Masters 35+',
  'Women\'s Masters 45+',
  'Women\'s Masters 55+',
  'Women\'s 2\'s Non Ranked',
  'Women\'s Pickup',
  'Coed',
  'Coed 2\'s Open',
  'Coed 2\'s AAA',
  'Coed 2\'s AA',
  'Coed 2\'s AA Early',
  'Coed 2\'s AA Late',
  'Coed 2\'s A',
  'Coed 2\'s A Early',
  'Coed 2\'s A Late',
  'Coed 2\'s BB',
  'Coed 2\'s BB Early',
  'Coed 2\'s BB Late',
  'Coed 2\'s B',
  'Coed 2\'s B Early',
  'Coed 2\'s B Late',
  'Coed 3\'s',
  'Coed 3\'s A',
  'Coed 3\'s AA',
  'Coed 3\'s B',
  'Coed 3\'s BB',
  'Coed 4\'s Advanced',
  'Coed 4\'s Intermediate',
  'Coed 4\'s Novice',
  'Coed 4\'s AAA',
  'Coed 4\'s AA',
  'Coed 4\'s AA Early',
  'Coed 4\'s AA Late',
  'Coed 4\'s A',
  'Coed 4\'s A Early',
  'Coed 4\'s A Late',
  'Coed 4\'s BB',
  'Coed 4\'s BB Early',
  'Coed 4\'s BB Late',
  'Coed 4\'s B',
  'Coed 4\'s B Early',
  'Coed 4\'s B Late',
  'Coed 4\'s',
  'Coed 6\'s',
  'Coed 6\'s AA',
  'Coed 6\'s A',
  'Coed 6\'s BB',
  'Coed 6\'s B',
  'Coed 6\'s Rec.',
  'Coed 6\'s Comp.',
  'Reverse Coed Open',
  'Reverse Coed AA',
  'Reverse Coed A',
  'Reverse Coed BB',
  'Reverse Coed B',
  'Coed Junior Doubles 18 & Under',
  'Coed Junior Doubles 16 & Under',
  'Coed Junior Doubles 14 & Under',
  'Coed Junior Doubles 12 & Under',
  'KOB Coed Open',
  'KOB Coed AA',
  'KOB Coed A',
  'KOB Coed B',
  'Coed Rec 1',
  'Coed Rec 2',
  'Coed 4\'s Open',
  'Coed 2\'s Novice',
  'Coed Masters 35+',
  'Coed Masters 45+',
  'Coed 6\'s Novice',
  'Coed 2\'s Non Ranked',
  'Coed Pickup',
  'Other',
  'Master\'s',
  'Century',
  'Junior 3\'s Boys 18U',
  'Junior 3\'s Boys 16U',
  'Junior 3\'s Boys 14U',
  'Junior 3\'s Boys 12U',
  'Junior Triples',
  'Junior 3\'s Girls 18U',
  'Junior 3\'s Girls 16U',
  'Junior 3\'s Girls 14U',
  'Junior 3\'s Girls 12U',
  'Junior Girls 6\'s',
  'Adult/Child',
  'Boys 18 & Under',
  'Boys 16 & Under',
  'Boys 14 & Under',
  'Boys 12 & Under',
  'Boys HS Division 1',
  'Boys HS Division 2',
  'Boys HS Division 3',
  'Girls 18 & Under Advanced',
  'Girls 18 & Under',
  'Girls 16 & Under Advanced',
  'Girls 16 & Under',
  'Girls 14 & Under Advanced',
  'Girls 14 & Under',
  'Girls 13 & Under',
  'Girls 12 & Under Advanced',
  'Girls 12 & Under',
  'Girls HS Division 1',
  'Girls HS Division 2',
  'Girls HS Division 3',
  'Girls 18U Open',
  'Girls 18U Club',
  'Girls 16U Open',
  'Girls 16U Club',
  'Girls 14U Open',
  'Girls 14U Club',
  'Girls 17U',
  'Girls 15U',
  'KOB Boys 18U',
  'KOB Boys 16U',
  'KOB Boys 14U',
  'KOB Boys 12U',
  'QOB Girls 18U',
  'QOB Girls 16U',
  'QOB Girls 14U',
  'QOB Girls 12U',
  '4\'s',
  'Cornhole',
  'Mother/Son less than 15',
  'Mother/Son 15 or older',
  'Mother/Daughter less than 15',
  'Mother/Daughter 15 or older',
  'Father/Son less than 15',
  'Father/Son 15 or older',
  'Father/Daughter less than 15',
  'Father/Daughter 15 or older',
  'Mentor and girl/boy 15',
  'Mentor and girl/boy less than 15',
  'Clinic',
  'Junior Girls Quads 18U',
  'Junior Girls Quads 16U',
  'Junior Girls Quads 14U',
  'Individual 4\'s',
  'Male Team 40+',
  'Male Team 60+',
  'Male Team 80+',
  'Male Team 100+',
  'Male Team 120+',
  'Female Team 40+',
  'CRPT',
  'Female Team 60+',
  'Female Team 80+',
  'Female Team 100+',
  'Female Team 120+',
  'Girls 18U Gold',
  'Girls 18U Silver',
  'Girls 18U Bronze',
  'Girls 16U Gold',
  'Girls 16U Silver',
  'Girls 16U Bronze',
  'Girls 14U Gold',
  'Girls 14U Silver',
  'Girls 14U Bronze',
  'Girls 12U Gold',
  'Girls 12U Silver',
  'Girls 12U Bronze',
  'Gender Blind Quads AA',
  'Boys 4\'s 16 & Under',
  'Boys 4\'s 18 & Under',
  'Girls 4\'s 12 & Under',
  'Girls 4\'s 14 & Under',
  'Girls 4\'s 16 & Under',
  'Girls 4\'s 18 & Under',
  'Parent/Child 2\'s A',
  'Parent/Child 2\'s Rec',
  'Coach/Player 2\'s',
  'Girls 14 & Under Rec.',
  'Girls 14 & Under A',
  'Girls 14 & Under AA',
  'Girls 16 & Under Rec.',
  'Girls 16 & Under A',
  'Girls 16 & Under AA',
  'Girls 18 & Under Rec.',
  'Girls 18 & Under A',
  'Girls 18 & Under AA',
  'Girls 10 & Under',
  'Under 6 Feet',
  'Gender Blind Quads A',
  'Gender Blind Quads BB',
  'Gender Blind Quads B',
  'Wait List - 2 player',
  'Wait List - 3 player',
  'Wait List - 4 player'
].sort()
